import axios from '@/plugins/axios'

/**
 * Create creatives
 */
const createCreative = async ({
  // eslint-disable-next-line camelcase
  advertiser_id,
  file,
  name,
  url,
  format,
  // eslint-disable-next-line camelcase
  is_desktop = false,
  // eslint-disable-next-line camelcase
  is_mobile = false,
  // eslint-disable-next-line camelcase
  is_app = false,
  logo = null,
  headline = '',
  // eslint-disable-next-line camelcase
  body_text = '',
  // eslint-disable-next-line camelcase
  call_to_action = '',
  // eslint-disable-next-line camelcase
  advertiser_display_name = '',
  // eslint-disable-next-line camelcase
  caption_url = ''
}) => {
  try {
    const form = new FormData()
    form.append('name', name)
    form.append('advertiser_id', advertiser_id)
    form.append('url', url)
    form.append('file', file)
    form.append('format', format)
    // eslint-disable-next-line camelcase
    form.append('is_desktop', is_desktop ? 1 : 0)
    // eslint-disable-next-line camelcase
    form.append('is_mobile', is_mobile ? 1 : 0)
    // eslint-disable-next-line camelcase
    form.append('is_app', is_app ? 1 : 0)
    if (logo) form.append('logo', logo)
    if (headline) form.append('headline', headline)
    // eslint-disable-next-line camelcase
    if (body_text) form.append('body_text', body_text)
    // eslint-disable-next-line camelcase
    if (call_to_action) form.append('call_to_action', call_to_action)
    // eslint-disable-next-line camelcase
    if (advertiser_display_name) form.append('advertiser_display_name', advertiser_display_name)
    // eslint-disable-next-line camelcase
    if (caption_url) form.append('caption_url', caption_url)

    const response = await axios.post('creatives', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get creatives
 */
const getCreatives = async ({
  advertiserId,
  format = '',
  layout = '',
  page = 1
}) => {
  try {
    const params = {
      advertiser_id: advertiserId,
      page: page
    }
    if (format) params.format = format
    if (layout) params.layout = layout
    const response = await axios('creatives', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`creatives/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update creative
 */
const updateCreative = async (id, {
  file = null,
  name,
  url,
  // eslint-disable-next-line camelcase
  is_desktop = false,
  // eslint-disable-next-line camelcase
  is_mobile = false,
  // eslint-disable-next-line camelcase
  is_app = false,
  logo = null,
  headline = '',
  // eslint-disable-next-line camelcase
  body_text = '',
  // eslint-disable-next-line camelcase
  call_to_action = '',
  // eslint-disable-next-line camelcase
  advertiser_display_name,
  // eslint-disable-next-line camelcase
  caption_url = ''
}) => {
  try {
    const form = new FormData()
    form.append('_method', 'PUT')
    form.append('name', name)
    form.append('url', url)
    if (file) form.append('file', file)
    // eslint-disable-next-line camelcase
    form.append('is_desktop', is_desktop ? 1 : 0)
    // eslint-disable-next-line camelcase
    form.append('is_mobile', is_mobile ? 1 : 0)
    // eslint-disable-next-line camelcase
    form.append('is_app', is_app ? 1 : 0)
    if (logo) form.append('logo', logo)
    if (headline) form.append('headline', headline)
    // eslint-disable-next-line camelcase
    if (body_text) form.append('body_text', body_text)
    // eslint-disable-next-line camelcase
    if (call_to_action) form.append('call_to_action', call_to_action)
    // eslint-disable-next-line camelcase
    if (advertiser_display_name) form.append('advertiser_display_name', advertiser_display_name)
    // eslint-disable-next-line camelcase
    if (caption_url) form.append('caption_url', caption_url)

    const response = await axios.post(`creatives/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createCreative,
  getCreatives,
  retryCreationOnPlatform,
  updateCreative
}
