<template>
  <div>
    <NativeCreativePreview
      :advertiser="advertiser"
      :creative="creative"
      :form="form"
      v-if="(form && form.format === 'NATIVE_DISPLAY') || (creative && creative.format === 'NATIVE_DISPLAY')"
    />
  </div>
</template>

<script>

import NativeCreativePreview from '@/components/creatives/CreativePreview/NativeCreativePreview'

export default {
  props: ['advertiser', 'creative', 'form'],
  name: 'CreativePreviewWrapper',
  components: {
    NativeCreativePreview
  }
}
</script>
